<template>
	<div>
		<Navbar />
	    <Menu />
	    <div class="app-content content">
	        <div class="content-wrapper">
	        	<router-view/>
	        </div>
	    </div>
	    <div class="sidenav-overlay"></div>
	    <div class="drag-target"></div>
	    <Footer />
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
import Footer from '@/layouts/components/Footer'
export default {
	components: {
		Navbar,
		Menu,
		Footer
	},
	async mounted () {
		await this.axios.get('/auth/me')
	}
}
</script>