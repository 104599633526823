<template>
	<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
        <div class="navbar-header">
            <ul class="nav navbar-nav d-block">
                <li class="nav-item mr-auto ml-auto">
                    <a class="navbar-brand" >
                        <div class="brand-logo">
                            <img src="/assets/img/ipm-min.png" class="img-fluid" alt="">
                        </div>
                    </a>
                </li>
            </ul>
        </div>

        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" style="padding-bottom: 100px;">
                <li class="nav-item" :class="$route.name == 'Home' ? 'active' : ''" v-can="'dashboard/read'">
                    <router-link to="/">
                        <i class="feather icon-home"></i>
                        <span class="menu-title">Dashboard</span>
                    </router-link>
                </li>

                <li class="nav-item has-sub" :class="
                    $route.name == 'UserIndex' ? 'active' :
                    $route.name == 'UserForm' ? 'active' :
                    $route.name == 'RoleIndex' ? 'active' : 
                    $route.name == 'RoleType' ? 'active' : 
                    $route.name == 'RoleAccess' ? 'active' : 
                    $route.name == 'RoleForm' ? 'active' :                     
                    ''"
                    v-can="['user/read','permission/read']">
                    <a>
                        <i class="feather icon-users"></i>
                        <span class="menu-title">User & Role Manajemen</span>
                    </a>

                    <ul class="menu-content">
                        <li v-can="'user/read'">
                            <router-link to="/user">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">User List</span>
                            </router-link>
                        </li>

                        <li v-can="'permission/read'">
                            <router-link to="/role">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Role</span>
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-sub" :class="
                    $route.name == 'KontraktorIndex' ? 'active' :
                    $route.name == 'KontraktorForm' ? 'active' :
                    $route.name == 'JenisPekerjaanIndex' ? 'active' :
                    $route.name == 'JenisPekerjaanForm' ? 'active' :
                    $route.name == 'JabatanIndex' ? 'active' :
                    $route.name == 'JabatanForm' ? 'active' :
                    $route.name == 'UnitForm' ? 'active' :
                    $route.name == 'UnitIndex' ? 'active' :
                    $route.name == 'BidangForm' ? 'active' :
                    $route.name == 'BidangIndex' ? 'active' :
                    $route.name == 'SubbidangForm' ? 'active' :
                    $route.name == 'SubbidangIndex' ? 'active' :
                    $route.name == 'UserPicIndex' ? 'active' :
                    $route.name == 'EmbedIndex' ? 'active' :
                    $route.name == 'EmbedForm' ? 'active' :
                    $route.name == 'AmandemenStatusIndex' ? 'active' :
                    $route.name == 'BankGaransiStatusIndex' ? 'active' :
                    $route.name == 'AnggaranStatusIndex' ? 'active' :
                    $route.name == 'ProgressMTUStatusIndex' ? 'active' :
                    ''" 
                    v-can="[
                        'kontraktor/read',
                        'jenis_pekerjaan/read',
                        'jabatan/read',
                        'unit/read',
                        'bidang/read',
                        'sub_bidang/read', 
                        'user_pic/read', 
                        'embed/read', 
                        'amandemen_status/read',
                        'bank_garansi_status/read',
                        'anggaran_status/read',
                        'progress_mtu_status/read'
                    ]">
                    <a >
                        <i class="feather icon-shield"></i>
                        <span class="menu-title">Master Data</span>
                    </a>

                    <ul class="menu-content">
                        <li v-can="'kontraktor/read'">
                            <router-link to="/kontraktor">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Kontraktor</span>
                            </router-link>
                        </li>
                        <li v-can="'kontraktor/read'">
                            <router-link to="/bank">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Bank</span>
                            </router-link>
                        </li>

                        <li v-can="'user_pic/read'">
                            <router-link to="/user_pic">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">User PIC</span>
                            </router-link>
                        </li>

                        <li v-can="'jenis_pekerjaan/read'">
                            <router-link to="/pekerjaan">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Jenis Pekerjaan</span>
                            </router-link>
                        </li>

                        <li v-can="'jabatan/read'">
                            <router-link to="/jabatan">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Jabatan</span>
                            </router-link>
                        </li>

                        <li v-can="'unit/read'">
                            <router-link to="/unit">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Unit</span>
                            </router-link>
                        </li>

                        <li v-can="'bidang/read'">
                            <router-link to="/bidang">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Bidang</span>
                            </router-link>
                        </li>

                        <li v-can="'sub_bidang/read'">
                            <router-link to="/subbidang">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Sub-Bidang</span>
                            </router-link>
                        </li>

                        <li v-can="'embed/read'">
                            <router-link to="/embed">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Embed</span>
                            </router-link>
                        </li>


                        <li v-can="'amandemen_status/read'">
                            <router-link to="/amandemen-status">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Amandemen Status</span>
                            </router-link>
                        </li>

                        <li v-can="'bank_garansi_status/read'">
                            <router-link to="/bank-garansi-status">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Bank Garansi Status</span>
                            </router-link>
                        </li>

                        <li v-can="'anggaran_status/read'">
                            <router-link to="/anggaran-status">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Anggaran Status</span>
                            </router-link>
                        </li>
                        
                        <li v-can="'progress_mtu_status/read'">
                            <router-link to="/progress-mtu-status">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Progress MTU Status</span>
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'KontrakIndex' ? 'active' :
                    $route.name == 'KontrakForm' ? 'active' :
                    $route.name == 'KontrakFormEdit' ? 'active' :                    
                    $route.name == 'KontrakDetail' ? 'active' :
                    $route.name == 'KontrakUpdateRekomendasi' ? 'active' :
                    $route.name == 'KontrakLihatRekomendasi' ? 'active' :
                ''" v-can="'kontrak/read'"
                    @click="menuCollapsed()">
                    <router-link to="/kontrak">
                        <i class="feather icon-grid"></i>
                        <span class="menu-title" data-i18n="User">{{role_id == 3 || role_id == 1 ? 'Dashboard' : 'Monitoring Project'}}</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'KontrakMTUIndex' ? 'active' :
                    $route.name == 'KontrakMTUForm' ? 'active' :
                    $route.name == 'KontrakMTUFormEdit' ? 'active' :                    
                    $route.name == 'KontrakMTUDetail' ? 'active' :
                ''" v-can="'kontrak_mtu/read'"
                    @click="menuCollapsed()">
                    <router-link to="/kontrak-mtu">
                        <i class="feather icon-grid"></i>
                        <span class="menu-title" data-i18n="User">Monitoring Kontrak MTU</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'CCTVIndex' ? 'active' :
                    $route.name == 'CCTVForm' ? 'active' :
                ''" v-can="'cctv/read'">
                    <router-link to="/cctv">
                        <i class="feather icon-video"></i>
                        <span class="menu-title">CCTV</span>
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'ApprovalIndex' ? 'active' :
                    $route.name == 'ApprovalDetailProgressShow' ? 'active' : ''"
                    v-can="'approval_progress/read'"
                >
                    <router-link to="/approval">
                        <i class="feather icon-check-circle"></i>
                        <!-- <img src="/iconscg.svg" class="imgIconApprove" alt=""> -->
                        <span class="menu-title">Approval Progress</span>
                        <span class="badge badge-button rounded-pill red-danger">{{progress_count}}</span>
                        <!-- :class="$route.name == 'ApprovalIndex' || 'ApprovalDetailProgressShow'  ? 'active text-body' : 'badge-primary'" -->
                    </router-link>
                </li>

                <li class="nav-item" :class="
                    $route.name == 'LiveMonitoring' ? 'active' : ''" v-can="'live_monitoring/read'"
                    @click="menuCollapsed()">
                    <router-link to="/live_monitoring">
                        <i class="feather icon-map"></i>
                        <span class="menu-title">Live Monitoring</span>
                    </router-link>
                </li>

                <li class="nav-item has-sub" :class="
                    $route.name == 'LaporanMonitoringIndex' ? 'active' :
                    $route.name == 'LaporanProjectIndex' ? 'active' :
                    $route.name == 'LaporanProgressIndex' ? 'active' :
                    $route.name == 'LaporanKendalaIndex' ? 'active' :
                    $route.name == 'LaporanSolusiIndex' ? 'active' :
                    $route.name == 'LaporanAmandemenIndex' ? 'active' :
                    $route.name == 'LaporanBapIndex' ? 'active' :
                    ''" 
                    v-can="[
                        'laporan/monitoring/read',
                        'laporan/project/read',
                        'laporan/progress/read',
                        'laporan/kendala/read',
                        'laporan/solusi/read',
                        'laporan/amandemen/read',
                        'laporan/bap/read',
                        'laporan/mtu_kontrak/read',
                        'laporan/stp/read',
                        'laporan/stap/read',
                        'laporan/energize/read',
                        'laporan/bay_stp/read',
                        'laporan/ppt_pdf/read'
                    ]">
                    <a >
                        <i class="feather icon-bar-chart"></i>
                        <span class="menu-title">Laporan</span>
                    </a>

                    <ul class="menu-content">
                        <li v-can="'laporan/monitoring/read'">
                            <router-link to="/laporan-monitoring">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan Monitoring</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/project/read'">
                            <router-link to="/laporan-project">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan Project</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/progress/read'">
                            <router-link to="/laporan-progress">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan Progress</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/kendala/read'">
                            <router-link to="/laporan-kendala">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan Kendala</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/mtu_kontrak/read'">
                            <router-link to="/laporan-project-mtu">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan Project MTU</span>
                            </router-link>
                        </li>

                        <!-- <li>
                            <router-link to="/laporan-project-ai">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Project Perlu Penyesuain AI</span>
                            </router-link>
                        </li> -->

                        <!-- <li>
                            <router-link to="/laporan-bg-pelaksanaan">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Jaminan Pelaksanaan Akan Berakhir</span>
                            </router-link>
                        </li> -->

                        <li v-can="'laporan/stp/read'">
                            <router-link to="/laporan-stp">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Proyek Telah STP UIP JBB</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/stap/read'">
                            <router-link to="/laporan-stap">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Proyek Telah STAP UIP JBB</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/stp/read'">
                            <router-link to="/laporan-stop">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Proyek Telah STOP UIP JBB</span>
                            </router-link>
                        </li>
                        

                        <!-- dev-irhash -->
                        <li v-can="'laporan/energize/read'">
                            <router-link to="/laporan/kontrak/energize">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Proyek Telah Energize UIP JBB</span>
                            </router-link>
                        </li>
                        <li v-can="'laporan/bay_stp/read'">
                            <router-link to="/laporan/kontrak/bay/energize">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Bay Telah Energize UIP JBB</span>
                            </router-link>
                        </li>
                        <li v-can="'laporan/slo/read'">
                            <router-link to="/laporan/kontrak/slo">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Proyek Telah SLO UIP JBB</span>
                            </router-link>
                        </li>
                        <li v-can="'laporan/bay_slo/read'">
                            <router-link to="/laporan/kontrak/bay/slo">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Bay Telah SLO UIP JBB</span>
                            </router-link>
                        </li>
                        <li v-can="'laporan/ppt_pdf/read'">
                            <router-link to="/laporan/ppt-pdf">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Laporan PPT & PDF</span>
                            </router-link>
                        </li>
                        <!-- end dev-irhash branch -->

                        <li v-can="'laporan/solusi/read'">
                            <router-link to="/laporan-solusi">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan Solusi</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/amandemen/read'">
                            <router-link to="/laporan-amandemen">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan Amandemen</span>
                            </router-link>
                        </li>

                        <li v-can="'laporan/bap/read'">
                            <router-link to="/laporan-bap">
                                <i class="fa fa-circle"></i>
                                <span class="menu-item">Generate Laporan BAP</span>
                            </router-link>
                        </li>                        
                    </ul>
                </li>

                <li class="nav-item" v-can="'upload/ai_aki/read'" :class="
                    $route.name == 'AIIndex' ? 'active' : ''">
                    <router-link to="/upload-ai">
                        <i class="feather icon-upload"></i>
                        <span class="menu-title">Upload AI & AKI</span>
                    </router-link>
                </li>
                <!-- <li class="nav-item" :class="
                    $route.name == 'LaporanBulananIndex' ? 'active' :
                    ''" 
                    v-can="'laporan/bulanan/read'">
                    <router-link to="/laporan-bulanan">
                        <i class="feather icon-bar-chart"></i>
                        <span class="menu-title">Laporan Bulanan</span>
                    </router-link>
                </li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'Menu',
    data() {
        return {
            role_id: localStorage.getItem('role_id'),
            progress_count : null
        }
    },
    methods: {
        menuCollapsed(){
            $(function(){                
                $('body').removeClass('menu-expanded')
                $('body').addClass('menu-collapsed')
            })
        },
        async loadBadge() {
            try {
                const datas = (
                await this.axios.get("/v2/kontrak/curva-s/badge", { params: { status: 0 } })
                ).data;
                this.progress_count = datas.data;
            } catch (error) {
                console.log(error);
            } finally {
                // this.loadStatePage = false;
            }
        },
    },
    async mounted() {
        await this.loadBadge();
    },

}
</script>

<style scoped>
    .not-active{
        color: #7367F0 !important;
        background-color:#FFFFFF !important;
    }
    .imgIconApprove {
        width: 20px;
        height: 50px;
    }
    .red-danger{
        background-color: #D6515D;
    }
</style>