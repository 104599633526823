<template>
	<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
        <div class="navbar-wrapper">
            <div class="navbar-container content">
                <div class="navbar-collapse" id="navbar-mobile">
                    <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <!-- Mobile -->
                        <ul class="nav navbar-nav">
                            <li class="nav-item mobile-menu d-xl-none mr-auto">
                                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>
                        </ul>
                        <!-- Desktop -->
                        <ul class="nav navbar-nav bookmark-icons">
                            <li class="nav-item d-none d-lg-block">
                                <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>

                            <li class="nav-item" @click="fullscreen">
                                <a class="nav-link">
                                    <i v-if="isFullScreen == 0" class="ficon feather icon-maximize"></i>
                                    <i v-else class="ficon feather icon-minimize"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <ul class="nav navbar-nav float-right">
                        <li class="dropdown dropdown-notification nav-item">
                            <a class="nav-link nav-link-label mr-1" href="#" data-toggle="dropdown">
                                <i class="ficon feather icon-bell"></i>
                                <span v-if="notifications.length > 0"  class="badge badge-pill badge-danger badge-up">{{ notifications.filter(n => n.is_read == false).length }}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                <li class="dropdown-menu-header">
                                    <div class="dropdown-header m-0">
                                        <span class="notification-title">Notifikasi</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="count-notif-block">
                                        <p class="font-600 text-primary mb-0">Semua ({{ notifications.length }})</p>
                                        <a href="javascript:void(0)" class="font-500 text-primary mb-0" @click="readAllNotif">Tandai Semua Telah Dibaca</a>
                                    </div>
                                </li>
                                <li class="scrollable-container media-list">
                                    <Notification v-for="(notif, ix) in notifications" :key="ix" :data="notif" @readNotif="readNotif" />
                                </li>
                                <li v-if="false" class="dropdown-menu-footer">
                                    <a class="dropdown-item p-1 text-center" href="javascript:void(0)">View all notifications</a>
                                </li>
                            </ul>
                        </li>
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <span>
                                    <img class="round" :src="temp_photo?temp_photo:'/assets/img/icon/profile.png'" alt="avatar" height="40" width="40">
                                </span>

                                <div class="user-nav d-sm-flex d-none align-items-start ml-1">
                                    <span class="user-name text-bold-600 ml-0">{{ $store.state.user.name }}</span>
                                    <span class="user-status">{{ $store.state.user.position_name }}</span>
                                </div>
                            </a>
                            <div class="dropdown-menu">
                                <router-link to="/user/edit_profile" tag="a" class="dropdown-item">
                                    <i class="feather icon-user"></i> Edit Profile
                                </router-link>
                                <div class="dropdown-divider"></div>
                                <a href="javascript:;" @click="logout" class="dropdown-item">
                                	<i class="feather icon-power"></i> Logout
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { EventBus } from '@/plugins/eventBus.js'
import Notification from './Notification.vue'
import $ from 'jquery'

export default {
	name: 'Navbar',
    components: {
        Notification
    },
    data() {
        return {
            temp_photo: null,
            isFullScreen: 0,
            notifications: []
        }
    },
    mounted() {
        let _ = this
        _.getNotification();
        // _.$echo.channel(`pubchan`)
        //     .listen(`.pubchan`, (e) => {
        //         console.log(e)
        //     });

        // _.$echo.private('App.User.' + _.$store.state.user.id)
        //     .notification((notification) => {
        //         console.log(notification);
        //         _.getNotification()
        //     });

        // _.$echo.private(`private-user-${_.$store.state.user.id}`)
        //     .listen(`.user_priv`, (e) => {
        //         console.log(e)
        //     });

        // _.$echo.private(`private-no_update_2_week_${_.$store.state.user.id}`)
        //     .listen(`.no_update_2_week`, (msg) => {
        //         console.log('no_update_2_week notif')
        //         console.log(msg)
        //         let notif = _.notifications
        //         _.notifications = [ msg, ...notif ]
        //         console.log(_.notifications)
        //     })

        // _.$echo.private(`private-project_finish_${_.$store.state.user.id}`)
        //     .listen(`.project_finish`, (msg) => {
        //         let notif = _.notifications
        //         _.notifications = [ msg, ...notif ]
        //         console.log(_.notifications)
        //     })

        // _.$echo.private(`private-project_end_in_75_${_.$store.state.user.id}`)
        //     .listen(`.project_end_in_75`, (msg) => {
        //         let notif = _.notifications
        //         _.notifications = [ msg, ...notif ]
        //         console.log(_.notifications)
        //     })

        // _.$echo.private(`private-project_end_in_60_${_.$store.state.user.id}`)
        //     .listen(`.project_end_in_60`, (msg) => {
        //         let notif = _.notifications
        //         _.notifications = [ msg, ...notif ]
        //         console.log(_.notifications)
        //     })

        // _.$echo.private(`private-project_end_in_45_${_.$store.state.user.id}`)
        //     .listen(`.project_end_in_45`, (msg) => {
        //         let notif = _.notifications
        //         _.notifications = [ msg, ...notif ]
        //         console.log(_.notifications)
        //     })

        // _.$echo.private(`private-document_permohonan_uploaded_${_.$store.state.user.id}`)
        //     .listen(`.document_permohonan_uploaded`, (msg) => {
        //         let notif = _.notifications
        //         _.notifications = [ msg, ...notif ]
        //         console.log(_.notifications)
        //     })

        // _.$echo.private(`private-document_rekomendasi_uploaded_${_.$store.state.user.id}`)
        //     .listen(`.document_rekomendasi_uploaded`, (msg) => {
        //         let notif = _.notifications
        //         _.notifications = [ msg, ...notif ]
        //         console.log(_.notifications)
        //     })

        if ( _.$store.state.user.profile_picture != 'null' ) {
            _.temp_photo = _.$store.state.user.profile_picture
        }

        EventBus.$on('changeProfilePicture', () => {
            _.temp_photo = _.$store.state.user.profile_picture
        })
    },
	methods: {
		logout() {
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			this.$store.dispatch('logout')
			this.$router.push('/login')
        },
        fullscreen(){
            let elem = document.documentElement
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (elem.requestFullScreen) {
                    elem.requestFullScreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullScreen) {
                    elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
                this.isFullScreen = 1
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                this.isFullScreen = 0
            }
        },
        getNotification() {
            let _ = this
            _.axios.get('/notification/all')
                .then(resp => {
                    _.notifications = resp.data.data || []
                })
        },
        readNotif(notification_id) {
            let _ = this
            _.notifications = _.notifications.map(n => {
                if ( notification_id == n.id ) {
                    return { ...n, is_read: true }
                } else {
                    return n
                }
            })
        },
        readAllNotif() {
            let _ = this
            _.axios.get('/notification/read_all')
                .then(resp=> {
                    console.log(resp)
                    _.getNotification()
                })
                .catch(err=> {
                    console.log(err)
                })
        }
	}
}
</script>